.home {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerContainer {
  width: auto;
  margin-left: 30px;
}

.home .headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 70px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: rgb(255, 255, 255);
}

.home .headerContainer p {
  margin-top: 15px;
  padding: 0;
  font-size: 40px;
  font-weight: lighter;
  color: rgb(255, 255, 255);
}

.headerContainer button {
  background-color: goldenrod;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  width: 180px;
  text-decoration: none;
  color: rgb(44, 34, 34);
  font-size: 20px;
}

.headerContainer button:hover {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s ease-in;
}

.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 80px;
  height: 80px;
  z-index: 1000;
}

.whatsapp-icon img {
  width: 100%;
  height: 100%;
}


/* mobile view  */

@media only screen and (max-width: 600px) {
  .home {
    justify-content: center;
    align-items: center;
  }

  .headerContainer {
    margin-left: 0;
    border-radius: 10px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
  }

  .home .headerContainer h1 {
    font-size: 40px;
    height: 30px;
    margin: 30px 0px;
    color: white;
  }

  .home .headerContainer p {
    font-size: 30px;
    color: white;
  }

  .headerContainer button {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
  }

  .whatsapp-icon {
    width: 60px;
    height: 60px;
    bottom: 10px;
    right: 10px;
  }
}





.home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerContainer {
  text-align: center;
}

.headerContainer .logo {
  width: 500px;
  height: auto;
  margin-bottom: 20px;
}

.headerContainerP {
  font-size: 50px;
  font-family: auto;
}


/* mobile view */
@media only screen and (max-width: 600px) {
  .headerContainer .logo {
    width: 160px;
  }

  .headerContainer p {
    font-size: 24px;
  }

  .headerContainerP {
    font-size: 20px;
  }

}